/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .copyright-content {
        margin-bottom: 80px;
    }
    /* .copyrights{
        margin-bottom: 50px;
    } */
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}