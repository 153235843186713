.header-menu {
    color: #8E8E8E;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    transition: 0.3s;
}

.header-menu:hover {
    color: #3875BF;
}

.ic-icons {
    width: 34px;
    height: 34px;
    margin-bottom: 4px;
}

.ql-ic-icons {
    width: 34px;
    height: 34px;
    margin-right: 16px;
}

.quick-links-box {
    text-decoration: none;
    transition: 0.3s;
}

.quick-links-box .ql-content:hover {
    background-color: #3875BF;
    color: #ffffff;
    transition: 0.3s ease-out;
}

.gallery-icon, .subscribe-icon {
    color: #EB8A4E;
}

.youtube-icon, .youtube-shorts-icon {
    color: #FF3D00;
}

.temple-home-page {
    height: 100vh;
}

.temple-logo {
    max-width: 100%;
    width: 300px;
}

.main-header {
    height: 100px;
}

.home-page-content {
    padding-top: 24px;
}

.template-email {
    color: #686868;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}

.template-email:hover {
    color: #3875BF !important;
}

.quick-links {
    margin-left: 85px;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    /* .temple-home-page {
        display: grid;
    } */

    .main-header {
        order: 3;
    }

    .temple-title {
        /* order: 1; */
        margin-top: 0px !important;
    }

    .copyright-content {
        order: 2;
    }

    .main-title {
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }

    .main-header {
        height: 80px;
        background-color: #EFF4FF;
        position: fixed;
        width: 100%;
        bottom: 0;
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    }

    .main-container {
        padding: 0 !important;
    }

    .home-page-content {
        /* order: 2; */
        padding-top: 0px;
    }

    .header-menu {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .quick-links {
        margin-left: 24px;
    }

    .footer-section {
        padding-bottom: 112px !important;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}