a {
    color: #3875BF;
    text-decoration: none;
}

a:active {
    color: #3875BF;
}

.icon-email, .icon-phone {
    padding-right: 16px;
}


.header-main-menu {
    text-decoration: none !important;
    margin: 0 20px;
    padding: 16px 0px;
    color: #8E8E8E;
}

.active {
    color: #3875BF !important;
    background-color: transparent !important;
    transition: 0.3s;
    font-weight: bold;
}

.inactive {
    color: #8E8E8E;
    font-weight: 400;
}

.header-nav .MuiToolbar-root {
    min-height: 55px !important;
}

ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
}
ul li a{
    text-decoration: none;
    background-color: transparent;
}

ul li a:hover{
    color: #3875BF;
}

.email-link, .contact-number {
    display: flex;
    margin-right: 24px;
}


/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */
    .email-link, .contact-number {
        margin-right: 0px !important;
    }

    .top-header {
        justify-content: flex-end !important;
        display: grid !important;
        height: 70px !important;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}