.book-image {
    max-width: 100%;
    width: auto;
    border-radius: 2px;
}

.book-gallery-section {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.book-gallery-section .donation-btn:hover {
    background-color: #84AEE1;
    color: #ffffff !important;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #ffffff;
    transition: 0.3s ease-out;
}

.book-gallery-section:hover {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
}

.book-name {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px; */
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px !important;
    color: #686868;
    font-weight: 500 !important;
    text-align: center;
}

.selectedBooks {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px !important;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .donation-btn {
        padding: 6px 6px !important;
    }

    .book-name {
        font-size: 14px !important;
    }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}