.gallery-image {
    max-width: 100%;
    width: 100%;
}

.temple-description {
    width: 500px;
    margin: 0 auto !important;
    color: #8E8E8E;
}

.photos-section {
    width: 225px;
    height: 225px;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */
    .temple-description {
        width: 100%;
    }

    .photos-section {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}