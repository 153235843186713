.bottom-header {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
}

.bottom-main-menu {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    color: #8E8E8E;
}

.active {
    color: #3875BF !important;
    background-color: transparent !important;
    transition: 0.3s;
    font-weight: bold;
}

.inactive {
    color: #8E8E8E;
    font-weight: 400;
}

.ic-icons {
    width: 34px;
    height: 34px;
    margin-bottom: 2px;
}

ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
}
ul li a{
    text-decoration: none;
    background-color: transparent;
}

ul li a:hover{
    color: #3875BF;
}