* {
  box-sizing: border-box !important;
  font-family: "NotoSans-Regular" !important;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFF4FF !important;
}

/* style for font face */
@font-face {
  font-family: "NotoSans-Regular";   /*Can be any text*/
  src: local("NotoSans-Regular"),
    url("./fonts/NotoSans-Regular.woff2") format("woff2"),
    url("./fonts/NotoSans-Regular.woff") format("woff"),
    url("./fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSans-Medium";   /*Can be any text*/
  src: local("NotoSans-Medium"),
    url("./fonts/NotoSans-Medium.woff2") format("woff2"),
    url("./fonts/NotoSans-Medium.woff") format("woff"),
    url("./fonts/NotoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSans-Bold";   /*Can be any text*/
  src: local("NotoSans-Bold"),
    url("./fonts/NotoSans-Bold.woff2") format("woff2"),
    url("./fonts/NotoSans-Bold.woff") format("woff"),
    url("./fonts/NotoSans-Bold.ttf") format("truetype");
}

.MuiFormControl-root {
  margin: 0 !important;
}

