.user-selected-puja {
    width: 100%;
    height: auto;
    background-color: transparent !important;
}

.puja-image {
    /* max-width: 100%; */
    /* width: 100%; */
    border-radius: 2px;
    border: 1px solid #f9f9f9;
}

.stepper-label .MuiStepLabel-iconContainer svg {
    color: #686868;
}

.stepper-label .MuiStepLabel-labelContainer {
    background-color: #686868;
    width: auto;
    padding: 6px 12px;
    border-radius: 50px;
}

.stepper-label .MuiStepLabel-label {
    color: #ffffff !important;
}

.mypuja-booking-screen .MuiStepContent-root {
    border-left: 1px dashed #686868;
}

.mypuja-booking-screen .MuiStepContent-root:nth-last-of-type() {
    border-left: none !important;
}

.mypuja-booking-screen .MuiStepConnector-line {
    border-left: 1px dashed #686868;
}

.horizontal-line {
    border-color: #D9D9D9 !important;
    border-style: solid;
}


/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .mypuja-booking-screen {
        margin-bottom: 80px;
    }

    .stepContent-btn {
        margin: 0 !important;
        display: grid;
    }

    .user-selected-puja {
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */

    .stepContent-btn {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}