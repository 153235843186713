.form-control-spacing {
    margin-bottom: 40px;
}

.label-required {
    color: #CC1817;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .DevoteeDetails{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 30px !important;
    }

    .form-control-spacing {
        margin-bottom: 24px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox
input[type=number] {
  -moz-appearance: textfield;
} */

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}