.puja-image {
    /* max-width: 100%;
    width: 100%; */
    border-radius: 2px;
    max-width: 100%;
    max-height: 100%;
   width: auto;
   height: 210px;
    border: 1px solid #f9f9f9;
}

.puja-gallery-section {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.puja-gallery-section .donation-btn:hover {
    background-color: #84AEE1;
    color: #ffffff !important;
    font-weight: 500;
    border-radius: 40px;
    border: 1px solid #ffffff;
    transition: 0.3s ease-out;
}

.puja-gallery-section:hover {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
}

.puja-homa-name {
    font-weight: 500 !important;
    color: #3875BF;
    min-height: 48px;
    /* font-size: 14px !important; */
}

.donation-btn {
    font-size: 14px;
}

.noDataFound {
    width: 250px;
    height: auto;
    opacity: 0.5;
}

.noPujaFound {
    width: 100%;
    text-align: center;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */

    .donation-btn {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    /* phones */

    .donation-btn {
        font-size: 12px !important;
    }

    .puja-homa-name {
        word-break: break-all;
        margin: 0 7px !important;
    }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}