.form-control-spacing {
    margin-bottom: 40px;
}

.label-donation-confirmlabel {
    color: #010101;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .donation-confirmation {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 30px !important;
    }

    .form-control-spacing {
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}