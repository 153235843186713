.tooltip-style .MuiTooltip-tooltip {
    height: 100px !important;
    align-items: center;
    display: flex;
    font-size: 16px !important;
    background-color: #ffffff !important;
    color: #8E8E8E !important;
    border: 1px solid #8E8E8E;
}

.order-confirm-table .MuiTableCell-root,
.order-confirm-table .MuiTableCell-root,
.order-confirm-table .MuiTableCell-root,
.order-confirm-table .MuiTableCell-root {
    border-bottom: none !important;
}

/* Media query style */
@media only screen and (min-width: 768px) {
    /* tablets and desktop */
}

@media only screen and (max-width: 767px) {
    /* phones */

    .DevoteeDetails{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 30px !important;
    }

    .form-control-spacing {
        margin-bottom: 24px;
    }

    .books-ordered-screen {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 24px !important;
    }

    .order-books-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
}