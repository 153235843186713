.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Full height of the viewport */
    text-align: center;
    color: #3875BF !important;
    background-color: transparent !important;
}

h1 {
    margin: 0;
    
}