.quote {
    color: #101010;
    font-size: 22px;
}

.sub-title {
    color: #101010;
    font-size: 18px;
    margin : 1px;
}
.div-space {
    margin-bottom : 20px;
    }